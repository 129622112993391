@font-face {
    font-family: "Poppins";
    src: url("fonts-local/Poppins/Poppins-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Poppins";
    src: url("fonts-local/Poppins/Poppins-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
}

// @font-face {
//   font-family: "PlayfairDisplay";
//   src: url("fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf") format("truetype");
//   font-style: normal;
//   font-weight: 500;
// }
// @font-face {
//   font-family: "PlayfairDisplay";
//   src: url("fonts/PlayfairDisplay/PlayfairDisplay-Bold.ttf") format("truetype");
//   font-style: normal;
//   font-weight: 700;
// }
// @font-face {
//   font-family: "Oswald";
//   src: url("fonts/Oswald/Oswald-Regular.ttf") format("truetype");
//   font-style: normal;
//   font-weight: 500;
// }
// @font-face {
//   font-family: "Oswald";
//   src: url("fonts/Oswald/Oswald-Bold.ttf") format("truetype");
//   font-style: normal;
//   font-weight: 700;
// }
// @font-face {
//   font-family: "Montserrat";
//   src: url("fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
//   font-style: normal;
//   font-weight: 500;
// }
// @font-face {
//   font-family: "Montserrat";
//   src: url("fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
//   font-style: normal;
//   font-weight: 700;
// }
html {
    font-size: 14px;
}

body {
    font-family: "Poppins";
}

.stage {
    background: transparent;
    // a4 width as defined by pdf generator
    width: 100%;
}

.stageCol {
    background: #eee;
    background: #eee;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-left: 0;
}

.scaleIndicator {
    position: fixed;
    background: #ddd;
    padding: 2px 5px;
    bottom: 0;
    left: 0;
    z-index: 10;

    button {
        padding: 0 !important;
        width: 20px !important;
        min-width: 20px;
        font-weight: bold;
        font-size: 18px;
        height: 20px;
    }
}

// custom context menu -obs
.custom-context {
    border: solid 1px #ccc;
    display: inline-block;
    margin: 5px;
    background: #FFF;
    color: #000;
    font-family: sans-serif;
    cursor: pointer;
    font-size: 12px;
}

.custom-context-item {
    border-bottom: dotted 1px #ccc;
    padding: 5px 25px;
}

.custom-context-item-last {
    padding: 5px 25px;
}

.designer-sidebar {
    background: #fff;
    border-left: 1px solid #ccc;
    padding: 0;
    overflow-y: auto;
    height: calc(100vh - 70px);
    overflow-x: hidden;
}

.image-content {
    position: relative;
    border: 1px solid #ddd;
    margin: 0 15px 15px 0;
    float: left;
    cursor: pointer;
    .image-delete {
        position: absolute;
        top: -12px;
        right: -12px;
        background: white;
        border-radius: 50px;
    }
}

.imageThumb {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    padding: 3px;
    img {
        max-width: 100%;
    }
}

.your-images-header {
    border-bottom: 1px solid #222;
}

.addShapes {
    padding-top: 0;
}

.shapeAttributes {
    padding: 15px;
    border-bottom: 1px solid #ccc;
    &:empty {
        padding: 0;
        border: 0;
    }
}

.add-shape-button {
    background: #333 !important;
    margin-right: 15px !important;
    margin-bottom: 15px !important;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    &:hover {
        background: #222 !important;
    }
    .MuiButton-label {}
    .MuiButton-startIcon {
        margin: 0 auto;
    }
    .MuiButton-iconSizeMedium> :first-child {
        font-size: 30px;
    }
    &.Mui-disabled {
        color: #aaa !important;
    }
    &.add-mandatory-text-button {
        .MuiButton-label {
            flex-direction: row;
            svg {
                margin-right: 10px;
            }
        }
    }
}

.qr-icon {
    font-size: 25px;
}

.MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
}

.MuiAccordionDetails-root {
    flex-wrap: wrap;
}

.sketch-picker {
    box-shadow: none !important;
    border: 0 !important;
    padding: 0 !important;
}

.button-full-width {
    width: 100%;
}

textarea,
input,
select {
    width: 100%;
    border: 1px solid #666;
    padding: 5px;
    border-radius: 3px;
    box-shadow: inset 0px 0px 15px -10px rgba(0, 0, 0, .5);
}


/* navbar*/

.navbar {
    border-bottom: 0;
    background: #444;
    color: #ccc;
    padding-top: 0;
    padding-bottom: 0;

    .applogo {
        color: #ddd;
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 1px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 50px;
        flex-direction: column;
        transform: translateY(-6px);
        text-transform: uppercase;
        border: 1px solid #666;
        padding: 5px;
        line-height: 1;
        .logo2 {
            letter-spacing: 2px;
        }
    }
    .nav-row {
        width: 100%;
        align-items: center;
        height: 70px;
    }
    .nav-right {
        justify-content: flex-end;
        display: flex;
    }
    button {
        margin-right: 15px;
    }
    label {
        margin-right: 30px;
    }
}

.topSelect {
    margin-top: 5px !important;
    .MuiInputLabel-filled.MuiInputLabel-shrink {
        transform: translate(11px, 2px) scale(0.75);
        margin-right: 0;
    }
    .MuiFilledInput-root:after {
        display: none !important;
    }
    .MuiSelect-select {
        background: white !important;
        padding-top: 15px;
        padding-bottom: 4px;
        border-radius: 3px !important;
        width: 100px;
    }
}

.tabpanel {
    padding: 15px;
    border: 1px solid #ccc;
}

label.attribute-label {
    display: block;
    border-bottom: 1px solid #ccc;
    margin-top: 15px;
}

label.attached {
    background: #ddd;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 0 4px;
    margin-bottom: 0;
    border: 1px solid #666;
    border-bottom: 0;
    position: relative;
    left: 2px;
}

.MuiTab-root {
    min-width: 90px !important;
}

.tab.Mui-selected {
    border: 1px solid #ccc;
    border-bottom: 0;
    border-bottom: 1px solid white;
    position: relative;
    top: 1px;
}

.font-style-group {
    width: 100%;
    button {
        flex-grow: 1;
    }
}

.MuiAccordionSummary-root {
    background: #eee !important;
}

.MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0 !important;
}

//move tab border to top
.PrivateTabIndicator-root-5 {
    bottom: auto;
    top: 0;
}

.MuiTabs-scroller,
.MuiTabs-root {
    overflow: visible !important;
}
.MuiTabs-root .Mui-disabled {
    color: #ddd;
}

//layerslist
.layersList {
    width: 100%;
}

.layersList h5 {
    margin: 15px;
}

.layerRow {
    border-bottom: 1px solid #ddd;
    font-size: 12px;
    padding: 10px 15px 10px 40px;
    list-style: none;
    position: relative;

    &::before {
        background: url(grab-handle.png) repeat;
        width: 15px;
        height: 30px;
        position: absolute;
        left: 10px;
        cursor: pointer;
        background-repeat: repeat;
        content: "";
        top: 6px;
    }
    &.no-handle::before {
        display: none;
    }
    &.isActive {
        background: #3c9d90;
        color: white;

        button {
            color: white !important
        }
    }
    .layerTag {
        border-radius: 3px;
        background: #ddd;
        padding: 3px;
        color: #222;
    }

    div {
        width: 70% !important;
    }
    button {
        width: 10% !important;
        position: absolute;
        right: 0;
        top: 0;
        min-width: 1px !important;
    }
    button:nth-child(2) {
        right: 30px;
    }
}

.layersList ul {
    padding: 0;
    list-style: none;
}

.layerRow:first-of-type {
    border-top: 1px solid #ddd;
}

.shapeattributes-sidebar {
    border-left: 1px solid #ccc;
    background: #fff;
    position: relative;
    z-index: 1;
    padding: 0;
    width: 300px;
    height: calc(100vh - 70px);
    overflow-y: auto;
    right: 16.66%;
    position: fixed;
}

.fileMenu {
    .MuiSvgIcon-root {
        margin-right: 10px;
    }
}

.genericOverlay {
    z-index: 9999;
    background: rgba(30, 30, 30, 0.8);
    display: flex;
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 4vw;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #ccc;

}

.quickSaveButton {
    padding: 6px 0 !important;
    min-width: 40px !important;
}

.accordion-instructions {
    font-size: 12px;
}
.MuiButtonGroup-root .MuiButton-root {
    padding-left: 5px;
    padding-right: 5px;
    min-width: 35px !important;
}
// nice way to add the previously used colors text.
.flexbox-fix:nth-child(4)::before {
    content: "Previously used colors:";
    font-size: 10px;
    width: 100%;
    margin-top: -10px;
    margin-bottom: 2px;
}

.template-selector-select {
    overflow-y: auto !important;
    overflow-x: auto !important;


}



.tpl-list {
    line-height: 2.5;
    .tplName {
        cursor: pointer;
        padding: 0 10px;
        position: relative;
        border-bottom: 1px solid #eee;
        &.active {
            background: #eee;
            border: 1px solid #ddd;
            border-radius: 2px;
            &:after {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 12.5px 15px 12.5px 0;
                border-color: transparent #eeeeee transparent transparent;
                position: absolute;
                right: -17px;
                top: 5px;
                z-index: 1;
            }
            &:before {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 13.5px 16px 13.5px 0;
                border-color: transparent #dddddd transparent transparent;
                position: absolute;
                right: -16px;
                top: 4px;
            }
        }
    }

}
.tpl-thumb {
    background: #eee;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 3px;
}

@media (max-width: 1200px) {
    html {
        font-size: 11px;
    }
    .accordion-instructions {
        font-size: 10px;
    }
    .navbar label {
        width: 92px;
    }
    .nav-left {
        display: flex;
        align-items: center;
    }
    .topSelect {
        margin-left: auto !important;
        width: 117px;
    }
}